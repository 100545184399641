import React from 'react';
import { MLabel, MIcon } from 'src/theme';
import { PATH_VERSION3, PATH_SUB_DISTRIBUTOR } from 'src/routes/paths';

import { Icon } from '@iconify/react';
import bookIcon from '@iconify/icons-akar-icons/book';
import bellFill from '@iconify-icons/eva/bell-fill';
import bxHome from '@iconify/icons-bx/bx-home';
import bxsOffer from '@iconify/icons-bx/bxs-offer';
import shippingBox02 from '@iconify/icons-akar-icons/shipping-box-02';
import boxIcon from '@iconify/icons-la/box';

import fileInvoice from '@iconify/icons-la/file-invoice';
import creditCardOutlined from '@iconify/icons-ant-design/credit-card-outlined';
import shopIcon from '@iconify/icons-vs/shop';
import deliveryTruck from '@iconify/icons-carbon/delivery-truck';

import peopleGroup from '@iconify/icons-akar-icons/people-group';
import personIcon from '@iconify/icons-akar-icons/person';
import buildingWarehouse from '@iconify/icons-tabler/building-warehouse';
import shoppingBagOutline from '@iconify/icons-eva/shopping-bag-outline';
import emailIcon from '@iconify/icons-carbon/email';
import reportIcon from '@iconify/icons-carbon/report';
import costingIcon from '@iconify/icons-carbon/calculator-check';

import currencyDollar from '@iconify/icons-carbon/currency-dollar';
import locationIcon from '@iconify/icons-ep/location';
import cubeIcon from '@iconify/icons-carbon/cube';
import parachuteBox from '@iconify/icons-fa-solid/parachute-box';

import documentBlank from '@iconify/icons-carbon/document-blank';

import cubesIcon from '@iconify/icons-fa/cubes';
import bookAlt from '@iconify/icons-bx/book-alt';
import cubeOutline from '@iconify/icons-eva/cube-outline';

import truckLoading from '@iconify/icons-la/truck-loading';
import moneyBill from '@iconify/icons-fa-solid/money-bill';
import agreementIcon from '@iconify/icons-icon-park-outline/agreement';

import stickyNoteO from '@iconify/icons-fa/sticky-note-o';
import outlineAssignmentReturn from '@iconify/icons-ic/outline-assignment-return';
import baselineGroup from '@iconify/icons-ic/baseline-group';

import idCard from '@iconify/icons-bx/id-card';
import doorIcon from '@iconify/icons-akar-icons/door';
// ----------------------------------------------------------------------

const path = (name) => `/static/icons/navbar/${name}.svg`;

const ICONS = {
  home: <Icon icon={bxHome} style={{ width: '2em', height: '2em'}}/>,
  userGuide: <Icon icon={bookIcon} style={{ width: '2em', height: '2em'}}/>,
  notifications: <Icon icon={bellFill} style={{ width: '2em', height: '2em'}}/>,
  offers: <Icon icon={bxsOffer} style={{ width: '2em', height: '2em'}}/>,
  products: <Icon icon={shippingBox02} style={{ width: '2em', height: '2em'}}/>,
  preOrder: <Icon icon={boxIcon} style={{ width: '2em', height: '2em'}}/>,
  purchaseOrder: <Icon icon={shoppingBagOutline} style={{ width: '2em', height: '2em'}}/>,
  order: <Icon icon={fileInvoice} style={{ width: '2em', height: '2em'}}/>,
  payment: <Icon icon={creditCardOutlined} style={{ width: '2em', height: '2em'}}/>,
  invoice: <Icon icon={currencyDollar} style={{ width: '2em', height: '2em'}}/>,
  tracking: <Icon icon={locationIcon} style={{ width: '2em', height: '2em'}}/>,
  shipment: <Icon icon={deliveryTruck} style={{ width: '2em', height: '2em'}}/>,
  retailer: <Icon icon={shopIcon} style={{ width: '2em', height: '2em'}}/>,
  role: <Icon icon={peopleGroup} style={{ width: '2em', height: '2em' }}/>,
  user: <Icon icon={personIcon} style={{ width: '2em', height: '2em'}}/>,
  email: <Icon icon={emailIcon} style={{ width: '2em', height: '2em'}}/>,
  report: <Icon icon={reportIcon} style={{ width: '2em', height: '2em'}}/>,
  costing: <Icon icon={costingIcon} style={{ width: '2em', height: '2em'}}/>,
  pricing: <Icon icon={currencyDollar} style={{ width: '2em', height: '2em' }}/>,
  inbound: <Icon icon={cubeIcon} style={{ width: '2em', height: '2em'}}/>,
  import: <Icon icon={parachuteBox} style={{ width: '2em', height: '2em'}}/>,
  sku: <Icon icon={documentBlank} style={{ width: '2em', height: '2em' }}/>,
  inboundType: <Icon icon={cubeOutline} style={{ width: '2em', height: '2em' }}/>,
  productManagement: <Icon icon={cubesIcon} style={{ width: '2em', height: '2em' }}/>,
  courierManagement: <Icon icon={deliveryTruck} style={{ width: '2em', height: '2em' }} />,
  offerTerms: <Icon icon={bookAlt} style={{ width: '2em', height: '2em' }} />,
  paymentTerms: <Icon icon={creditCardOutlined} style={{ width: '2em', height: '2em'}}/>,
  filesImport: <Icon icon={reportIcon} style={{ width: '2em', height: '2em'}}/>,
  carrier: <Icon icon={truckLoading} style={{ width: '2em', height: '2em'}}/>,
  creditNote: <Icon icon={moneyBill} style={{ width: '2em', height: '2em' }}/>,
  agreementTerms: <Icon icon={agreementIcon} style={{ width: '2em', height: '2em' }}/>,
  profile: <Icon icon={stickyNoteO} style={{ width: '2em', height: '2em' }}/>,
  retailerGroup: <Icon icon={baselineGroup} style={{ width: '2em', height: '2em' }}/>,
  consignmentSKUOrder: <Icon icon={cubeOutline} style={{ width: '2em', height: '2em' }}/>,
  rma: <Icon icon={outlineAssignmentReturn} style={{ width: '2em', height: '2em'}}/>,
  consignmentRetailerStock: <Icon icon={cubesIcon} style={{ width: '2em', height: '2em' }}/>,
  companyAccount: <Icon icon={idCard} style={{ width: '2em', height: '2em' }}/>,
  subdistributorPortal: <Icon icon={doorIcon} style={{ width: '2em', height: '2em' }}/>
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    items: [
      {
        title: 'subdistributor portal',
        icon: ICONS.subdistributorPortal,
        href: PATH_SUB_DISTRIBUTOR.general.root,
        access: 'subdistributor',
        distributorAccess: 'subdistributor'
      }
    ]
  },
  {
    subheader: 'general',
    items: [
      {
        title: 'home',
        icon: ICONS.home,
        href: PATH_VERSION3.general.root,
        access: 'dashboard',
        distributorAccess: 'general'
      },
      {
        title: 'user',
        icon: ICONS.user,
        href: PATH_VERSION3.general.user,
        access: 'user',
        distributorAccess: 'user'
      },
      {
        title: 'user guide',
        icon: ICONS.userGuide,
        access: 'general',
        distributorAccess: 'general'
      },
      {
        title: 'notifications',
        icon: ICONS.notifications,
        href: PATH_VERSION3.general.notifications,
        access: 'general',
        distributorAccess: 'general'
      },
      {
        title: 'agreement terms',
        icon: ICONS.agreementTerms,
        href: PATH_VERSION3.general.agreementTerms,
        access: 'agreementTerms',
        distributorAccess: 'agreementTerms'
      },
      {
        title: 'public profile',
        icon: ICONS.profile,
        href: PATH_VERSION3.general.distributorProfile,
        access: 'publicProfile',
        distributorAccess: 'publicProfile'
      },
      {
        title: 'company account',
        icon: ICONS.companyAccount,
        href: PATH_VERSION3.general.companyAccount,
        access: 'companyAccount',
        distributorAccess: 'companyAccount'
      }
    ]
  },

  // ORDERS
  {
    subheader: 'Orders',
    items: [
      {
        title: 'order admin',
        icon: ICONS.order,
        href: PATH_VERSION3.general.orderAdminSubMenuSales.root,
        access: 'orderAdmin',
        distributorAccess: 'orderAdmin',
        items: [
          {
            title: 'sales',
            href: PATH_VERSION3.general.orderAdminSubMenuSales.root,
            access: 'orderAdminSubSales',
            distributorAccess: 'orderAdmin'
          },
          {
            title: 'finance',
            href: PATH_VERSION3.general.orderAdminSubMenuFinance.root,
            access: 'orderAdminSubFinance',
            distributorAccess: 'orderAdmin'
          },
          {
            title: 'logistic',
            href: PATH_VERSION3.general.orderAdminSubMenuLogistic.root,
            access: 'orderAdminSubLogistic',
            distributorAccess: 'orderAdmin'
          }
        ]
      },
      {
        title: 'all order',
        icon: ICONS.order,
        href: PATH_VERSION3.general.allOrder,
        access: 'allOrder',
        distributorAccess: 'allOrder'
      },
      {
        title: 'Sales Order',
        icon: ICONS.order,
        href: PATH_VERSION3.general.orderSales,
        access: 'orderSales',
        distributorAccess: 'salesOrder'
      },
      {
        title: 'Sales Order Manager',
        icon: ICONS.order,
        href: PATH_VERSION3.general.orderSalesManager,
        access: 'orderSalesManager',
        distributorAccess: 'salesOrderManager'
      },
      {
        title: 'consignment',
        icon: ICONS.order,
        href: PATH_VERSION3.general.orderConsignment,
        access: 'orderConsignment',
        distributorAccess: 'consignment'
      },
      {
        title: 'orders finance',
        icon: ICONS.order,
        href: PATH_VERSION3.general.orderFinance,
        access: 'orderFinance',
        distributorAccess: 'orderFinance'
      },
      {
        title: 'orders logistic',
        icon: ICONS.order,
        href: PATH_VERSION3.general.orderLogistic,
        access: 'orderLogistic',
        distributorAccess: 'orderLogistic'
      },
      {
        title: 'orders logistic manager',
        icon: ICONS.order,
        href: PATH_VERSION3.general.orderLogisticManager,
        access: 'orderLogisticManager',
        distributorAccess: 'orderLogisticManager'
      }
    ]
  },

  // INVOICING
  {
    subheader: 'invoicing',
    items: [
      {
        title: 'invoice admin',
        icon: ICONS.invoice,
        href: PATH_VERSION3.general.invoiceAdminSubMenuSales.root,
        access: 'invoicesAdmin',
        distributorAccess: 'invoicesAdmin',
        items: [
          {
            title: 'sales',
            href: PATH_VERSION3.general.invoiceAdminSubMenuSales.root,
            access: 'invoicesAdminSubSales',
            distributorAccess: 'invoicesAdmin'
          },
          {
            title: 'finance',
            href: PATH_VERSION3.general.invoiceAdminSubMenuFinance.root,
            access: 'invoicesAdminSubFinance',
            distributorAccess: 'invoicesAdmin'
          }
        ]
      },
      {
        title: 'invoices finance',
        icon: ICONS.invoice,
        href: PATH_VERSION3.general.invoiceFinance,
        access: 'invoicesFinance',
        distributorAccess: 'invoicesFinance'
      },
      {
        title: 'invoices finance manager',
        icon: ICONS.invoice,
        href: PATH_VERSION3.general.invoiceFinanceManager,
        access: 'invoicesFinanceManager',
        distributorAccess: 'invoicesFinanceManager'
      },
      {
        title: 'invoices sales',
        icon: ICONS.invoice,
        href: PATH_VERSION3.general.invoiceSales,
        access: 'invoicesSales',
        distributorAccess: 'invoicesSales'
      },
      {
        title: 'payment finance',
        icon: ICONS.payment,
        href: PATH_VERSION3.general.paymentFinance,
        access: 'paymentFinance',
        distributorAccess: 'paymentFinance'
      },
      {
        title: 'credit note',
        icon: ICONS.creditNote,
        href: PATH_VERSION3.general.creditNote,
        access: 'creditNote',
        distributorAccess: 'creditNote'
      },
      {
        title: 'track payment',
        icon: ICONS.order,
        href: PATH_VERSION3.general.trackPayment,
        access: 'trackPayment',
        distributorAccess: 'trackPayment'
      }
      /**{
        title: 'tracking sales',
        icon: ICONS.tracking,
        href: PATH_VERSION3.general.trackingSales,
        access: 'trackingSales'
      },
      {
        title: 'tracking logistic',
        icon: ICONS.tracking,
        href: PATH_VERSION3.general.trackingLogistic,
        access: 'trackingLogistic'
      }*/
    ]
  },

  // CONSIGNMENT MANAGEMENT
  {
    subheader: 'Consignment MGMT.',
    items: [
      {
        title: 'Products',
        icon: ICONS.consignmentSKUOrder,
        href: PATH_VERSION3.general.consignmentProduct,
        access: 'consignmentProducts',
        distributorAccess: 'consignmentProducts'
      },
      /**{
        title: 'SKU Order',
        icon: ICONS.consignmentSKUOrder,
        href: PATH_VERSION3.general.consignmentSKUOrder,
        access: 'consignmentSKUOrder',
        distributorAccess: 'consignmentSKUOrder'
      },*/
      {
        title: 'SKU Order',
        icon: ICONS.consignmentSKUOrder,
        href: PATH_VERSION3.general.consignmentSKUOrderV2,
        access: 'consignmentSKUOrder',
        distributorAccess: 'consignmentSKUOrder'
      },
      {
        title: 'Report',
        icon: ICONS.report,
        href: PATH_VERSION3.general.consignmentReport,
        access: 'consignmentReport',
        distributorAccess: 'consignmentReport'
      },
      {
        title: 'Stock Returnable',
        icon: ICONS.report,
        href: PATH_VERSION3.general.consignmentStockReturnable,
        access: 'consignmentStockReturnable',
        distributorAccess: 'consignmentStockReturnable'
      },
      {
        title: 'Retailer Stock',
        icon: ICONS.consignmentRetailerStock,
        href: PATH_VERSION3.general.consignmentRetailerStock,
        access: 'retailerStockProduct',
        distributorAccess: 'retailerStockProduct'
      }
    ]
  },
  
  // OFFER
  {
    subheader: 'Offers',
    items: [
      {
        title: 'offers sales',
        icon: ICONS.offers,
        href: PATH_VERSION3.general.offerSalesV2,
        access: 'offersSales',
        distributorAccess: 'offerSales'
      },
      {
        title: 'offers sales manager',
        icon: ICONS.offers,
        href: PATH_VERSION3.general.offerSalesManagerV2,
        access: 'offersSalesManager',
        distributorAccess: 'offerSalesManager'
      },
      {
        title: 'offers admin',
        icon: ICONS.offers,
        href: PATH_VERSION3.general.offerAdmin,
        access: 'offersAdmin',
        distributorAccess: 'offerAdmin'
      },
      {
        // depecrated
        title: 'offers super admin',
        icon: ICONS.offers,
        href: PATH_VERSION3.general.offerSuperAdmin,
        access: 'offersSuperAdmin',
        distributorAccess: 'offerSalesManager'
      },
      {
        title: 'closed offers',
        icon: ICONS.offers,
        href: PATH_VERSION3.general.closedOffersBuyerV2,
        access: 'closedOffersBuyer',
        distributorAccess: 'closedOffers'
      },
      {
        title: 'closed offers manager',
        icon: ICONS.offers,
        href: PATH_VERSION3.general.closedOffersBuyerManagerV2,
        access: 'closedOffersBuyerManager',
        distributorAccess: 'closedOffersManager'
      }
    ]
  },

  // PRE ORDER
  {
    subheader: 'Pre Order',
    items: [
      {
        title: 'pre-order sales',
        icon: ICONS.preOrder,
        href: PATH_VERSION3.general.preOrderSales,
        access: 'preOrderSales',
        distributorAccess: 'preOrderSales'
      },
      {
        title: 'pre-order sales manager',
        icon: ICONS.preOrder,
        href: PATH_VERSION3.general.preOrderSalesManager,
        access: 'preOrderSalesManager',
        distributorAccess: 'preOrderSalesManager'
      }
    ]
  },

  // PURCHASE ORDER
  {
    subheader: 'Purchase Order',
    items: [
      // {
      //   title: 'purchase order buyer',
      //   icon: ICONS.order,
      //   href: PATH_VERSION3.general.purchaseOrderBuyer,
      //   access: 'purchaseOrderBuyer'
      // },
      {
        title: 'purchase order buyer',
        icon: ICONS.order,
        href: PATH_VERSION3.general.purchaseOrderBuyerV2,
        access: 'purchaseOrderBuyer',
        distributorAccess: 'purchaseOrderBuyer'
      }
    ]
  },

  // PRICING
  {
    subheader: 'Pricing',
    items: [
      {
        title: 'pricing',
        icon: ICONS.pricing,
        href: PATH_VERSION3.general.pricing,
        access: 'costing',
        distributorAccess: 'pricing'
      }
      /**{
        title: 'costing',
        icon: ICONS.costing,
        href: PATH_VERSION3.general.costingView,
        access: 'costing'
      },
      {
        title: 'sku',
        icon: ICONS.sku,
        href: PATH_VERSION3.general.skuBuyer,
        access: 'skuBuyer'
      }*/
    ]
  },

  // LOOK UP DATA
  {
    subheader: 'lookup data',
    items: [
      {
        title: 'products',
        icon: ICONS.products,
        href: PATH_VERSION3.general.product,
        access: 'skuBuyer',
        distributorAccess: 'products'
      },
      {
        title: 'product management',
        icon: ICONS.productManagement,
        href: PATH_VERSION3.general.productCategory,
        access: 'productCategory',
        distributorAccess: 'productManagement'
      },
      {
        title: 'type',
        icon: ICONS.inboundType,
        href: PATH_VERSION3.general.inboundType,
        access: 'inboundType',
        distributorAccess: 'type'
      },
      {
        title: 'carrier',
        icon: ICONS.carrier,
        href: PATH_VERSION3.general.inboundCarrier,
        access: 'inboundCarrier',
        distributorAccess: 'carrier'
      },
      {
        title: 'courier management',
        icon: ICONS.courierManagement,
        href: PATH_VERSION3.general.courierManagement,
        access: 'courierManagement',
        distributorAccess: 'courierManagement'
      },
      {
        title: 'offers terms',
        icon: ICONS.offerTerms,
        href: PATH_VERSION3.general.offerTerms,
        access: 'offerTerms',
        distributorAccess: 'offersTerms'
      },
      {
        title: 'payment terms',
        icon: ICONS.paymentTerms,
        href: PATH_VERSION3.general.paymentTerms,
        access: 'paymentTerms',
        distributorAccess: 'paymentTerms'
      }
    ]
  },

  // PROCUREMENT
  {
    subheader: 'Procurement',
    items: [
      {
        title: 'inbound admin',
        icon: ICONS.inbound,
        href: PATH_VERSION3.general.inboundAdminSubMenuSales.root,
        access: 'inboundAdmin',
        distributorAccess: 'inboundAdmin',
        items: [
          {
            title: 'sales',
            href: PATH_VERSION3.general.inboundAdminSubMenuSales.root,
            access: 'inboundAdminSubSales',
            distributorAccess: 'inboundAdmin'
          },
          {
            title: 'finance',
            href: PATH_VERSION3.general.inboundAdminSubMenuFinance.root,
            access: 'inboundAdminSubFinance',
            distributorAccess: 'inboundAdmin'
          },
          {
            title: 'logistic',
            href: PATH_VERSION3.general.inboundAdminSubMenuLogistic.root,
            access: 'inboundAdminSubLogistic',
            distributorAccess: 'inboundAdmin'
          }
        ]
      },
      {
        title: 'inbound logistic',
        icon: ICONS.inbound,
        href: PATH_VERSION3.general.inboundLogistic,
        access: 'inboundLogistic',
        distributorAccess: 'inboundLogistic'
      },
      {
        title: 'inbound sales',
        icon: ICONS.inbound,
        href: PATH_VERSION3.general.inboundSales,
        access: 'inboundSales',
        distributorAccess: 'inboundSales'
      },
      {
        title: 'inbound finance',
        icon: ICONS.import,
        href: PATH_VERSION3.general.inboundFinance,
        access: 'importsFinance',
        distributorAccess: 'inboundFinance'
      },
      {
        title: 'RMA',
        icon: ICONS.rma,
        href: PATH_VERSION3.general.rmaV2,
        access: 'rma',
        distributorAccess: 'rma'
      }
      /**{
        title: 'RMA',
        icon: ICONS.rma,
        href: PATH_VERSION3.general.rma,
        access: 'inboundLogistic', // to change
        distributorAccess: 'procurement'
      }*/
    ]
  },

  // PROCUREMENT FILES
  {
    subheader: 'Procurement Files',
    items: [
      {
        title: 'COO files',
        icon: ICONS.filesImport,
        href: PATH_VERSION3.general.cooFiles,
        access: 'inboundLogistic',
        distributorAccess: 'cooFiles'
      },
      {
        title: 'LOI files',
        icon: ICONS.filesImport,
        href: PATH_VERSION3.general.loiFiles,
        access: 'inboundLogistic',
        distributorAccess: 'loiFiles'
      },
      {
        title: 'COC files',
        icon: ICONS.filesImport,
        href: PATH_VERSION3.general.cocFiles,
        access: 'inboundLogistic',
        distributorAccess: 'cocFiles'
      },
      {
        title: 'BL files',
        icon: ICONS.filesImport,
        href: PATH_VERSION3.general.blFiles,
        access: 'inboundLogistic',
        distributorAccess: 'blFiles'
      },
      {
        title: 'PL files',
        icon: ICONS.filesImport,
        href: PATH_VERSION3.general.plFiles,
        access: 'inboundLogistic',
        distributorAccess: 'plFiles'
      },
      {
        title: 'PO files',
        icon: ICONS.filesImport,
        href: PATH_VERSION3.general.poFiles,
        access: 'inboundLogistic',
        distributorAccess: 'poFiles'
      }
    ]
  },

  
  // MANAGEMENT
  {
    subheader: 'Management',
    items: [
      {
        title: 'retailer',
        icon: ICONS.retailer,
        href: PATH_VERSION3.general.retailer,
        access: 'retailer',
        distributorAccess: 'retailer'
      },
      {
        title: 'retailer Group',
        icon: ICONS.retailerGroup,
        href: PATH_VERSION3.general.retailerGroup,
        access: 'retailer',
        distributorAccess: 'retailerGroup'
      },
      {
        title: 'single sided retailers (SSR)',
        icon: ICONS.retailer,
        href: PATH_VERSION3.general.singleSidedRetailers.root,
        access: 'singleSidedRetailer',
        distributorAccess: 'singleSidedRetailer'
      },
      {
        title: 'sub distributor',
        icon: ICONS.retailer,
        href: PATH_VERSION3.general.subdistributor.root,
        access: 'subDistributor',
        distributorAccess: 'subDistributor'
      },
      {
        title: 'supplier',
        icon: ICONS.retailer,
        href: PATH_VERSION3.general.supplier,
        access: 'supplier',
        distributorAccess: 'supplier'
      },
      {
        title: 'reports',
        icon: ICONS.report,
        href: PATH_VERSION3.general.reports,
        access: 'reports',
        distributorAccess: 'reports'
      },
      {
        title: 'reports Finance',
        icon: ICONS.report,
        href: PATH_VERSION3.general.reportsFinance,
        access: 'reportsFinance',
        distributorAccess: 'reportsFinance'
      }
    ]
  },

  // SETTINGS
  {
    subheader: 'Settings',
    items: [
      {
        title: 'email',
        icon: ICONS.email,
        href: PATH_VERSION3.general.email,
        access: 'emailSettings',
        distributorAccess: 'email'
      }
    ]
  }

];

export default navConfig;
