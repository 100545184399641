import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  catalogueTitle: '',
  sortProductBy: 'alphabet-AZ',
  selectedProducts: [],
  originProducts: [],
  selectedData: []
}

const slice = createSlice({
  name: 'generateConsignmentProductCatalogue',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    setCatalogueTitle(state, action) {
      state.isLoading = false;
      state.catalogueTitle = action.payload;
    },

    setSortProductBy(state, action) {
      state.isLoading = false;
      state.sortProductBy = action.payload;
    },

    setSelectedProducts(state, action) {
      state.isLoading = false;
      state.selectedProducts = action.payload;
    },

    setOriginProducts(state, action) {
      state.isLoading = false;
      state.originProducts = action.payload;
    },

    setSelectedData(state, action) {
      state.isLoading = false;
      state.selectedData = action.payload;
    },

    resetGenerateConsignmentProductCatalogue(state) {
      state.catalogueTitle = '';
      state.sortProductBy = 'alphabet-AZ';
      state.selectedProducts = [];
      state.originProducts = [];
      state.selectedData = [];
    }
  }
})

// Reducer
export default slice.reducer;

// Actions
export const {
} = slice.actions;

// ----------------------------------------------------------------------

export function setCatalogueTitle(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setCatalogueTitle(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setSortProductBy(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setSortProductBy(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setSelectedProducts(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setSelectedProducts(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setOriginProducts(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setOriginProducts(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setSelectedData(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setSelectedData(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function resetGenerateConsignmentProductCatalogue() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.resetGenerateConsignmentProductCatalogue())
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}